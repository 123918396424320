"use client";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { Fragment, useState } from "react";
import { cn } from "src/lib/utils/stylesUtils";
import { Button } from "src/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Factory, HandCoins } from "lucide-react";
import { usePathname } from "next/navigation";

const publicNavigation = [
  { name: "Features", href: "/features" },
  { name: "Grow", href: "/grow" },
  {
    name: "Monetize",
    href: "#",
    children: [
      {
        name: "Large Custom Orders",
        description: "Outsource custom orders and split the profits with a partner factory.",
        href: "/monetize/large-custom-orders",
        icon: Factory,
      },
      {
        name: "Bulk Purchasing",
        description: "Save up to 40% on your blanks costs thanks to our volume savings.",
        href: "/monetize/save-money-on-your-blanks",
        icon: HandCoins,
      },
    ],
  },
  { name: "Pricing", href: "/pricing" },
];

export default function FlagshipHeader() {
  const selectedPath = usePathname();

  //const router = useRouter();

  let navigation = publicNavigation;

  return (
    <Disclosure as="nav" className="sticky top-0 z-50">
      {({ open }) => (
        <div className="bg-white border-b border-slate-100">
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 ">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-neil hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start ">
                <div className="flex flex-shrink-0 items-center">
                  <Link href="/">
                    <img className="h-8 w-auto" src={"/flagship/logo2.png"} alt="Flexchain Logo" />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item, idx) => (
                      <>
                        {!item.children && (
                          <Link
                            key={idx}
                            href={item.href}
                            className={cn(
                              item.href === selectedPath
                                ? "underline decoration-2 underline-offset-4" //"bg-neil-300 text-white"
                                : "text-slate-900 hover:underline hover:decoration-2 hover:underline-offset-4",
                              "rounded-md px-3 py-2 text-sm font-normal"
                            )}
                            aria-current={item.href === selectedPath ? "page" : undefined}
                          >
                            {item.name}
                          </Link>
                        )}
                        {item.children && (
                          <Popover>
                            <PopoverTrigger
                              className={cn(
                                item.href === selectedPath
                                  ? "bg-gray-900 text-white"
                                  : "text-slate-900 hover:underline hover:decoration-2 hover:underline-offset-4",
                                "rounded-md px-3 py-2 text-sm font-normal flex flex-row items-center space-x-1"
                              )}
                            >
                              <span>{item.name}</span>
                              <ChevronDownIcon aria-hidden="true" className="h-4 w-4" />
                            </PopoverTrigger>
                            <PopoverContent className="p-0 w-96">
                              <div>
                                {item.children.map((itm, idx) => (
                                  <Link href={itm.href} key={idx}>
                                    <div key={item.name} className="flex gap-x-6 rounded-lg p-4 hover:bg-gray-50 group">
                                      <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-neil-500">
                                        <itm.icon
                                          aria-hidden="true"
                                          className="h-6 w-6 text-gray-600 group-hover:text-white"
                                        />
                                      </div>
                                      <div>
                                        <span className="font-semibold text-gray-900 text-sm">{itm.name}</span>
                                        <p className="mt-1 text-gray-500 text-sm">{itm.description}</p>
                                      </div>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            </PopoverContent>
                          </Popover>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/*<button
                  type="button"
                  className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>*/}

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  {/*profile && (
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <Avatar className="h-8 w-8">
                          {profile && profile.avatar_url ? (
                            <AvatarImage
                              src={
                                supabase.storage
                                  .from("avatars")
                                  .getPublicUrl(profile.avatar_url).data
                                  .publicUrl
                              }
                            />
                          ) : profile &&
                            !profile.avatar_url &&
                            profile.full_name &&
                            profile.full_name.length > 0 ? (
                            <AvatarFallback>
                              {profile.full_name?.substring(0, 1)}
                              {profile.full_name
                                ?.split(" ")
                                [
                                  profile.full_name?.split(" ").length - 1
                                ].substring(0, 1)}
                            </AvatarFallback>
                          ) : (
                            <CircleUserRound className="bg-white w-full h-full text-slate-500" />
                          )}
                        </Avatar>
                      </Menu.Button>
                    </div>
                  )*/}
                  {
                    /*!profile*/ true && (
                      <>
                        <Link
                          key={"Login"}
                          href={"https://app.getflexchain.com/login"}
                          className={cn(
                            "hidden md:inline",
                            "/login" === selectedPath
                              ? "bg-gray-900 text-white"
                              : "text-slate-900 hover:underline hover:decoration-2 hover:underline-offset-4",
                            "rounded-md px-3 py-2 text-sm font-normal"
                          )}
                          aria-current={"/login" === selectedPath ? "page" : undefined}
                        >
                          Login
                        </Link>
                        <Link href={"https://app.getflexchain.com/register"}>
                          <Button className="bg-neil hover:bg-neil-400 rounded-none">Get Started</Button>
                        </Link>
                      </>
                    )
                  }
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {/*<Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Your Profile
                          </a>
                        )}
                            </Menu.Item> */}

                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            href={"/account"}
                            className={cn(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                          >
                            Settings
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            href={"/account/help"}
                            className={cn(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                          >
                            Support
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={async () => {
                              //signOut(router);
                            }}
                            className={cn(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            Sign out
                          </span>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item, idx) => (
                <Disclosure.Button
                  key={idx}
                  as="a"
                  href={item.href}
                  className={cn(
                    item.href === selectedPath
                      ? "bg-neil-300 text-white"
                      : "text-gray-600 hover:bg-neil hover:text-white",
                    "block rounded-none px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.href === selectedPath ? "page" : undefined}
                >
                  <div className="flex flex-row items-center space-x-1">
                    <span>{item.name}</span>
                    {item.children && <ChevronDownIcon aria-hidden="true" className="h-4 w-4" />}
                  </div>
                </Disclosure.Button>
              ))}

              <div className="">
                <Link href="https://app.getflexchain.com/register">
                  <Button className="flagship-button w-full">Get Started</Button>
                </Link>
              </div>
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
